import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Layout, Card, Button, Form, Upload, Input, Select, Table, Tooltip, Popconfirm, Modal, Image, Pagination } from 'antd';
import { withCookies } from "react-cookie";

// ICONS
import { IoPencil, IoTrashBin } from 'react-icons/io5';

// CSS
import './styles.css';
import HeaderComponent from "../../components/HeaderComponent";

const Screen = () => {

    // CONFIG STATES
    const [visible, setVisible] = useState(false)
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [list, setList] = useState([])
    const [data, setData] = useState()

    const [pagCurrent, setPagCurrent] = useState(1)
    const [pagSize, setPagSize] = useState(10)
    const [pagTotal, setPagTotal] = useState(10)

    const [formadd] = Form.useForm();
    const [form] = Form.useForm();

    useEffect(() => {
        if (!load) {
            listData(search);
            setLoad(true)
        }
    }, [load, search]);

    const listData = async (value = search, pagCurrentThis = pagCurrent, pagSizeThis = pagSize) => {
        setLoading(true)
        const formData = new FormData()
    
        formData.append('search', value)
        formData.append('current', pagCurrentThis)
        formData.append('pageSize', pagSizeThis)
    
        await fetch('https://painel.v2models.com/php/clientes/list.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            setList(res.data);
            setPagTotal(res.summary.QTDE)
            setLoading(false)
        })
    
    }

    const showModal = () => {
        setVisible(true);
    };
    
    const handleCancel = () => {
        setVisible(false);
    };

    const loadData = (item) => {
        showModal()
        form.setFieldsValue({
            cliente_id: item.id,
            cliente_nome: item.nome,
            cliente_status: item.valor_status,
        })
        setData(item)
    }

    const loadSearch = (value) => {
        setSearch(value)
        listData(value, pagCurrent, pagSize)
    }

    const deleteData = async (id) => {
        const formData = new FormData()
    
        formData.append('id', id)
    
        await fetch('https://painel.v2models.com/php/clientes/del.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) { } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })
    }

    // CONFIG FORM
    const normFile = (e) => {
        console.log(e)
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    const onFinish = async (values) => {
        const formData = new FormData()
    
        formData.append('id', values.cliente_id)
        formData.append('nome', values.cliente_nome)
        formData.append('status', values.cliente_status)
        formData.append('files', values.cliente_imagem[0].originFileObj)
    
        await fetch('https://painel.v2models.com/php/clientes/add.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) {
                    formadd.setFieldsValue({
                        cliente_id: 0,
                        cliente_imagem: null,
                        cliente_nome: null,
                        cliente_status: null
                    })
                } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })
        
    };

    const _onChange = (values) => {
        setPagCurrent(values)
        listData(search, values, pagSize)
    }

    const onFinishEdit = async (values) => {
        const formData = new FormData()
    
        formData.append('id', values.cliente_id)
        formData.append('nome', values.cliente_nome)
        formData.append('status', values.cliente_status)
        
        formData.append('files', (values.cliente_imagem_new) ? values.cliente_imagem_new[0].originFileObj : null)
        await fetch('https://painel.v2models.com/php/clientes/edit.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) {
                    form.setFieldsValue({
                        cliente_id: 0,
                        cliente_imagem_new: null,
                        cliente_nome: null,
                        cliente_status: null
                    })
                    handleCancel()
                } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })
    };

    const _onPaginationSize = async (size) => {
        setPagSize(size)
        listData(search, pagCurrent, size)
    }

    // CONFIG TABLE
    const columns = [
        { title: 'Imagem', dataIndex: 'imagem', key: 'imagem', render: (_, record) => {
            return (
                <Image
                    width={100}
                    src={record.imagem}
                />
            )
        }},
        { title: 'Nome', dataIndex: 'nome', key: 'nome' },
        { title: 'Status', dataIndex: 'status', key: 'status'},
        { title: 'Ações', dataIndex: '', key: 'x', render: (_, record) => {
            return (
                <>
                    <Tooltip title="Editar">
                        <Button type="primary" icon={<IoPencil />} className="buttons_table" onClick={() => loadData(record)} />
                    </Tooltip>
                    <Popconfirm title="Tem certeza que deseja deletar esse item?" onConfirm={() => deleteData(record.id)}>
                        <Button type="danger" icon={<IoTrashBin />} className="buttons_table" />
                    </Popconfirm>
                </>
            )
        } }
    ];

    return (
        <Layout>
            <HeaderComponent title="Clientes" subtitle="Criação, alteração e exclusão de clientes" />

            {/* CARD ADD */}
            <Card size="small" title="Adicionando Clientes" className="cliente_card_add">
                <Form labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} name="form_data" onFinish={onFinish} form={formadd}>
                    <Form.Item
                        name="cliente_imagem"
                        label="Imagem"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: 'Por favor, selecione uma imagem' }]}
                    >
                        <Upload maxCount={1} accept="image/png, image/jpeg" name="logo" action="" listType="picture">
                            <Button >Clique para selecionar a imagem</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="cliente_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="cliente_nome"
                        label="Nome"
                        rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="cliente_status" label="Status" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                        <Select
                            placeholder="Selecione uma opção"
                        >
                            <Select.Option value="1">Ativo</Select.Option>
                            <Select.Option value="0">Inativo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            {/* CARD EDIT */}
            <Modal title="Editar Cliente" visible={visible} footer={[]} onCancel={handleCancel}>
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} name="form_data" onFinish={onFinishEdit} form={form}>
                    <Form.Item
                        name="cliente_imagem_old"
                        label="Imagem"
                    >
                        <Image
                            width={200}
                            src={data?.imagem}
                        />
                    </Form.Item>
                    <Form.Item
                        name="cliente_imagem_new"
                        label="Nova Imagem"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload maxCount={1} accept="image/png, image/jpeg" name="logo" listType="picture">
                            <Button >Clique para selecionar a imagem</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="cliente_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="cliente_nome"
                        label="Nome"
                        rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="cliente_status" label="Status" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                        <Select
                            placeholder="Selecione uma opção"
                        >
                            <Select.Option value="1">Ativo</Select.Option>
                            <Select.Option value="0">Inativo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* CARD LIST */}
            <Card size="small" title="Lista de Clientes" className="cliente_card_list">
                
                <Input value={search} onChange={(e) => loadSearch(e.target.value)} placeholder="Pesquisar..." className="cliente_card_list_search"/>
                <Table
                    columns={columns}
                    dataSource={list}
                    loading={loading}
                    pagination={false}
                />
                <Pagination 
                    style={{marginTop: 10, float: 'right'}}
                    showSizeChanger
                    onShowSizeChange={(current, size) => _onPaginationSize(size)}
                    defaultCurrent={pagCurrent}
                    total={pagTotal}
                    onChange={_onChange}
                />
            </Card>
        </Layout>
    )

}


export default withCookies(Screen);