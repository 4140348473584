import './App.css';
import AppStack from './routes/AppStack';

const App = () => {

  return (
    <AppStack />
  )

}

export default App;
