import React, { useState } from "react";
import { Layout, Card, Button, Form, Upload, Input, Select, Table, Tooltip, Popconfirm, Modal, Image, Row, Col, Divider } from 'antd';
import { withCookies } from "react-cookie";

// ICONS
import { IoPencil, IoTrashBin } from 'react-icons/io5';

// CSS
import './styles.css';
import HeaderComponent from "../../components/HeaderComponent";

const Screen = () => {

    // CONFIG STATES
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState('')
    const [item, setItem] = useState([])
    const [form] = Form.useForm();
    const [formImagem] = Form.useForm();

    const showModal = () => {
        setVisible(true);
    };
    
    const handleCancel = () => {
        setVisible(false);
    };

    const loadData = (item) => {
        showModal()
        form.setFieldsValue({
            trabalho_id: item.id,
        })
        formImagem.setFieldsValue({
            modelo_id: item.id
        })
        setItem(item.list_images)
    }

    const loadSearch = (value) => {
        setSearch(value)
        console.log(value)
    }

    const deleteData = (item) => {
        console.log(item)
    }

    // CONFIG FORM
    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    const onFinishEdit = (values) => {
        console.log('Received values of form edit: ', values);
    };

    // CONFIG TABLE
    const columns = [
        { title: 'Trabalho', dataIndex: 'trabalho', key: 'trabalho', sorter: (a, b) => a.trabalho - b.trabalho },
        { title: 'Modelos', dataIndex: 'modelos', key: 'modelos' },
        { title: 'Ações', dataIndex: '', key: 'x', render: (_, record) => {
            return (
                <>
                    <Tooltip title="Editar">
                        <Button type="primary" icon={<IoPencil />} className="buttons_table" onClick={() => loadData(record)} />
                    </Tooltip>
                    <Popconfirm title="Tem certeza que deseja deletar esse item?" onConfirm={() => deleteData(record)}>
                        <Button type="danger" icon={<IoTrashBin />} className="buttons_table" />
                    </Popconfirm>
                </>
            )
        } }
    ];

    const deletePhotoData = (item) => {
        console.log(item)
    }

    const onFinishImagens = (values) => {
        console.log('Received values of form imagens: ', values);
    };

    const data = [{imagem: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png', status: 'Ativo', valor_status: 1, nome: 'Testando', id: 1, list_images: [
        {url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'},
        {url: 'https://joeschmoe.io/api/v1/random'},
    ]}]

    return (
        <Layout>
            <HeaderComponent title="Trabalhos e Modelos" subtitle="Criação, alteração e exclusão de trabalhos e modelos" />

            {/* CARD ADD */}
            <Card size="small" title="Adicionando Trabalhos e Modelos" className="cliente_card_add">
                <Form layout="vertical" name="form_data" onFinish={onFinish}>
                    <Form.Item
                        name="trabalho_modelo_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="trabalho_modelo_trabalho" label="Trabalhos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                        <Select 
                            placeholder="Selecione uma opção"
                        >
                            <Select.Option value="1">Ativo</Select.Option>
                            <Select.Option value="2">kjk</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="trabalho_modelo_modelos[]" label="Modelos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                        <Select mode="multiple"
                            placeholder="Selecione uma opção"
                        >
                            <Select.Option value="1">Ativo</Select.Option>
                            <Select.Option value="2">kjk</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            {/* CARD EDIT */}
            <Modal title="Editar Trabalho e Modelos" width={1000} visible={visible} footer={[]} onCancel={handleCancel}>
                <Card size="small" title="Editar Trabalho e Modelos" className="cliente_card_list">
                <Form layout="vertical" name="form_data" onFinish={onFinishEdit} form={form}>
                <Form.Item
                        name="trabalho_modelo_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="trabalho_modelo_trabalho" label="Trabalhos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                        <Select 
                            placeholder="Selecione uma opção"
                        >
                            <Select.Option value="1">Ativo</Select.Option>
                            <Select.Option value="2">kjk</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="trabalho_modelo_modelos[]" label="Modelos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                        <Select mode="multiple"
                            placeholder="Selecione uma opção"
                        >
                            <Select.Option value="1">Ativo</Select.Option>
                            <Select.Option value="2">kjk</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
                </Card>
            </Modal>

            {/* CARD LIST */}
            <Card size="small" title="Lista de Trabalhos" className="cliente_card_list">
                
                <Input value={search} onChange={(e) => loadSearch(e.target.value)} placeholder="Pesquisar..." className="cliente_card_list_search"/>
                <Table
                    columns={columns}
                    dataSource={data}
                />
            </Card>
        </Layout>
    )

}


export default withCookies(Screen);