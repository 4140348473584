import React, { useState } from "react";
import Swal from "sweetalert2";
import { Col, Image, Row, Typography, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { withCookies, useCookies } from "react-cookie";

// IMAGE
import Logo from '../../assets/images/logo.png';

// CSS
import './styles.css';

const Screen = () => {

    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')
    const [cookies, setCookie] = useCookies(['login']);

    const navigate = useNavigate()
    const { Text } = Typography

    const onLogin = async () => {

        const formData = new FormData()

        formData.append('login', login)
        formData.append('senha', senha)

        await fetch('https://painel.v2models.com/php/home/login.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) {
                    setCookie('login', 'TRUE', { path: '/' });
                    navigate('dashboard')
                } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        })

    }



    return (
        <Row className="login_screen">
            <Col className="login_box" md={6} sm={12} xs={20}>
                <Image
                    width={100}
                    height={'100%'}
                    preview={false}
                    src={Logo}
                    className="login_box__image"
                />
                <Row className="login_box__box">
                    <Col className="login_box__box_col_text" span={24}>
                        <Text className="login_box__box_text">Entre com seus dados para iniciar a sua sessão</Text>
                    </Col>
                    <Col span={24}>
                        <Text className="login_box__box_label">Login</Text>
                    </Col>
                    <Col span={24}>
                        <Input value={login} onChange={(e) => setLogin(e.target.value)} className="login_box__box_input" placeholder="Digite seu login..." />
                    </Col>
                    <Col span={24}>
                        <Text className="login_box__box_label">Senha</Text>
                    </Col>
                    <Col span={24}>
                        <Input value={senha} onChange={(e) => setSenha(e.target.value)} className="login_box__box_input" placeholder="Digite sua senha..." type='password' />
                    </Col>
                    <Col span={24}>
                        <Button className="login_box__box_button button_primary" type="primary" onClick={onLogin} >Entrar</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}


export default withCookies(Screen);