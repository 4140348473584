import React, { useState } from "react";
import { Layout, Card, Button, Form, Upload, Input, Select, Table, Tooltip, Popconfirm, Modal, Image, Row, Col, Divider } from 'antd';
import { withCookies } from "react-cookie";

// ICONS
import { IoPencil, IoTrashBin, IoHomeSharp } from 'react-icons/io5';

// CSS
import './styles.css';
import HeaderComponent from "../../components/HeaderComponent";

const Screen = () => {

    // CONFIG STATES
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState('')
    const [item, setItem] = useState([])
    const [form] = Form.useForm();
    const [formImagem] = Form.useForm();

    const showModal = () => {
        setVisible(true);
    };
    
    const handleCancel = () => {
        setVisible(false);
    };

    const loadData = (item) => {
        showModal()
        form.setFieldsValue({
            modelo_altura: item.altura,
            modelo_busto: item.busto,
            modelo_cabelos: item.cabelos,
            modelo_cintura: item.cintura,
            modelo_id: item.id,
            modelo_imagem_new: item.imagem_new,
            modelo_imagem_old: item.imagem_old,
            modelo_manequim: item.manequim,
            modelo_nome: item.nome,
            modelo_nome_artistico: item.nome_artistico,
            modelo_olhos: item.olhos,
            modelo_pele: item.pele,
            modelo_peso: item.peso,
            modelo_quadril: item.quadril,
            modelo_sapatos: item.sapatos
        })
        formImagem.setFieldsValue({
            modelo_id: item.id
        })
        setItem(item.list_images)
    }

    const loadSearch = (value) => {
        setSearch(value)
        console.log(value)
    }

    const deleteData = (item) => {
        console.log(item)
    }

    const deletePhotoData = (item) => {
        console.log(item)
    }

    // CONFIG FORM
    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    const onFinishImagens = (values) => {
        console.log('Received values of form imagens: ', values);
    };

    const onFinishEdit = (values) => {
        console.log('Received values of form edit: ', values);
    };

    // CONFIG TABLE
    const columns = [
        { title: 'Foto', dataIndex: 'foto', key: 'foto', render: (_, record) => {
            return (
                <Image
                    width={100}
                    src={record.imagem}
                />
            )
        }},
        { title: 'Nome', dataIndex: 'nome', key: 'nome', sorter: (a, b) => a.nome - b.nome },
        { title: 'Nome Art.', dataIndex: 'nome_artistico', key: 'nome_artistico', sorter: (a, b) => a.nome_artistico - b.nome_artistico },
        { title: 'Home', dataIndex: 'home', key: 'home', sorter: (a, b) => a.home - b.home },
        { title: 'Banner', dataIndex: 'banner', key: 'banner', sorter: (a, b) => a.banner - b.banner },
        { title: 'Destaque', dataIndex: 'destaque', key: 'destaque', sorter: (a, b) => a.destaque - b.destaque },
        { title: 'Disponivel', dataIndex: 'disponivel', key: 'disponivel', sorter: (a, b) => a.disponivel - b.disponivel },
        { title: 'Ações', dataIndex: '', key: 'x', render: (_, record) => {
            return (
                <>
                    <Tooltip title="Enviar Home">
                        <Button type="ghost" icon={<IoHomeSharp />} className="buttons_table" onClick={() => {}} />
                    </Tooltip>
                    <Tooltip title="Editar">
                        <Button type="primary" icon={<IoPencil />} className="buttons_table" onClick={() => loadData(record)} />
                    </Tooltip>
                    <Popconfirm title="Tem certeza que deseja deletar esse item?" onConfirm={() => deleteData(record)}>
                        <Button type="danger" icon={<IoTrashBin />} className="buttons_table" />
                    </Popconfirm>
                </>
            )
        } }
    ];

    const data = [{imagem: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png', status: 'Ativo', valor_status: 1, nome: 'Testando', id: 1, list_images: [
        {url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'},
        {url: 'https://joeschmoe.io/api/v1/random'},
    ]}]

    return (
        <Layout>
            <HeaderComponent title="Modelo" subtitle="Alteração e exclusão de modelos" />

            {/* CARD EDIT */}
            <Modal title="Editar Modelo" width={1000} visible={visible} footer={[]} onCancel={handleCancel}>
                <Card size="small" title="Editar modelo" className="cliente_card_list">
                <Form layout="vertical" name="form_data" onFinish={onFinishEdit} form={form}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="modelo_imagem_old"
                                label="Imagem"
                            >
                                <Image
                                    width={200}
                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="modelo_imagem_new"
                                label="Nova Imagem"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                            >
                                <Upload maxCount={1} accept="image/png, image/jpeg" name="logo" listType="picture">
                                    <Button >Clique para selecionar a imagem</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="modelo_nome"
                                label="Nome"
                                rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <Form.Item
                                name="modelo_nome_artistico"
                                label="Nome Artístico"
                                rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="modelo_pele" label="Pele" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="Branca">Branca</Select.Option>
                                    <Select.Option value="Morena">Morena</Select.Option>
                                    <Select.Option value="Negra">Negra</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={5}>
                            <Form.Item name="modelo_olhos" label="Olhos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="Azuis">Azuis</Select.Option>
                                    <Select.Option value="Castanhos">Castanhos</Select.Option>
                                    <Select.Option value="Castanhos Claros">Castanhos Claros</Select.Option>
                                    <Select.Option value="Mel">Mel</Select.Option>
                                    <Select.Option value="Pretos">Pretos</Select.Option>
                                    <Select.Option value="Verde">Verde</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={6}>
                            <Form.Item name="modelo_cabelos" label="Cabelos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="Castanhos">Castanhos</Select.Option>
                                    <Select.Option value="Castanhos Claros">Castanhos Claros</Select.Option>
                                    <Select.Option value="Loiros">Loiros</Select.Option>
                                    <Select.Option value="Pretos">Pretos</Select.Option>
                                    <Select.Option value="Ruivos">Ruivos</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={3}>
                            <Form.Item
                                name="modelo_altura"
                                label="Altura(cm)"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={3}>
                            <Form.Item
                                name="modelo_peso"
                                label="Peso(kg)"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="modelo_manequim"
                                label="Manequim"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item
                                name="modelo_busto"
                                label="Busto"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item
                                name="modelo_cintura"
                                label="Cintura"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item
                                name="modelo_quadril"
                                label="Quadril"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item
                                name="modelo_sapatos"
                                label="Sapatos"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="modelo_observacao"
                                label="Observações"
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="modelo_home" label="Exibir na home" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="1">Sim</Select.Option>
                                    <Select.Option value="0">Não</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item name="modelo_banner" label="Exibir no banner" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="1">Sim</Select.Option>
                                    <Select.Option value="0">Não</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item name="modelo_status" label="Status" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="1">Ativo</Select.Option>
                                    <Select.Option value="0">Inativo</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item name="modelo_sexo" label="Sexo" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="FA">Feminino</Select.Option>
                                    <Select.Option value="MA">Masculino</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item name="modelo_kids" label="Kids" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="1">Sim</Select.Option>
                                    <Select.Option value="0">Não</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="modelo_posicao" label="Posição" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Input type='number' />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Form.Item
                        name="modelo_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
                </Card>
                <Card size="small" title="Galeria de fotos" className="cliente_card_list">
                    <Row gutter={24}>
                    {item.map((foto, index) => (
                        <Col key={index} span={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Image
                                width={150}
                                src={foto.url}
                            />
                            <Button style={{marginTop: 8}} size="small" type="danger" onClick={() => deletePhotoData(foto)}>Deletar</Button>
                        </Col>
                    ))}
                    </Row>
                    <Divider />
                    <Form layout="vertical" name="form_data" style={{marginTop: 10}} onFinish={onFinishImagens} form={formImagem}>
                        <Form.Item
                            name="modelo_galeria"
                            label="Selecionar fotos"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload maxCount={20} multiple accept="image/png, image/jpeg" name="logo" listType="picture">
                                <Button >Clique para selecionar a imagem</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name="modelo_id"
                            hidden
                            initialValue={0}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Salvar Arquivos
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>

            {/* CARD LIST */}
            <Card size="small" title="Lista de Slide" className="cliente_card_list">
                
                <Input value={search} onChange={(e) => loadSearch(e.target.value)} placeholder="Pesquisar..." className="cliente_card_list_search"/>
                <Table
                    columns={columns}
                    dataSource={data}
                    
                />
            </Card>
        </Layout>
    )

}


export default withCookies(Screen);