import React from "react";
import { Layout } from 'antd';
import { withCookies } from "react-cookie";

// CSS
import './styles.css';
import HeaderComponent from "../../components/HeaderComponent";

const Screen = () => {

    return (
        <Layout>
            <HeaderComponent title="Dashboard" subtitle="Versão 2.0" />
        </Layout>
    )

}


export default withCookies(Screen);