import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Layout, Card, Button, Form, Upload, Input, Select, Table, Tooltip, Popconfirm, Modal, Image, Row, Col, Divider, Pagination } from 'antd';
import { withCookies } from "react-cookie";

// ICONS
import { IoPencil, IoTrashBin, IoHome, IoClose } from 'react-icons/io5';
import { MdWebAssetOff, MdWebAsset } from 'react-icons/md';

// CSS
import './styles.css';
import HeaderComponent from "../../components/HeaderComponent";

const Screen = () => {

    // CONFIG STATES
    const [visible, setVisible] = useState(false)
    const [visibleAdd, setVisibleAdd] = useState(false)
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingFormNew, setLoadingFormNew] = useState(false)
    const [loadingFormEdit, setLoadingFormEdit] = useState(false)
    const [loadingFormImg, setLoadingFormImg] = useState(false)
    const [search, setSearch] = useState('')
    const [list, setList] = useState([])
    const [data, setData] = useState()

    const [pagCurrent, setPagCurrent] = useState(1)
    const [pagSize, setPagSize] = useState(10)
    const [pagTotal, setPagTotal] = useState(10)

    const [formadd] = Form.useForm();
    const [form] = Form.useForm();
    const [formImagem] = Form.useForm();

    useEffect(() => {
        if (!load) {
            listData(search);
            setLoad(true)
        }
    }, [load, search]);

    const showModal = () => {
        setVisible(true);
    };
    
    const handleCancel = () => {
        setVisible(false);
        setVisibleAdd(false);
    };

    const loadData = (item) => {
        showModal()
        form.setFieldsValue({
            modelo_altura: item.Altura,
            modelo_busto: item.Busto,
            modelo_cabelos: item.Cabelos,
            modelo_cintura: item.Cintura,
            modelo_id: item.id,
            modelo_imagem_old: item.imagem,
            modelo_manequim: item.Manequim,
            modelo_nome: item.Nome,
            modelo_nome_artistico: item.NomeArt,
            modelo_olhos: item.Olhos,
            modelo_pele: item.Pele,
            modelo_peso: item.Peso,
            modelo_quadril: item.Quadril,
            modelo_sapatos: item.Sapatos,
            modelo_home: item.exibe_na_home,
            modelo_banner: item.exibe_banner,
            modelo_status: item.valor_disponivel,
            modelo_observacao: item.Observacao,
            modelo_posicao: item.posicao,
        })
        formImagem.setFieldsValue({
            modelo_id: item.id
        })
        setData(item)
    }

    const loadSearch = (value) => {
        setSearch(value)
        listData(value, pagCurrent, pagSize)
    }

    const deleteData = async (id) => {
        const formData = new FormData()
    
        formData.append('id', id)
    
        await fetch('https://painel.v2models.com/php/menino/del.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) { } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })
    }

    const deletePhotoData = async (id) => {
        const formData = new FormData()
    
        formData.append('id', id)
    
        await fetch('https://painel.v2models.com/php/menino/galeria-del.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) { } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
            handleCancel()
        })
    }

    // CONFIG FORM
    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    const onFinish = async (values) => {

        setLoadingFormNew(true);

        const formData = new FormData()

        formData.append('id', values.modelo_id)
        formData.append('nome', values.modelo_nome)
        formData.append('nomeart', values.modelo_nome_artistico)
        formData.append('home', values.modelo_home)
        formData.append('observacao', values.modelo_observacao)
        formData.append('posicao', values.modelo_posicao)
        formData.append('banner', values.modelo_banner)
        formData.append('disponivel', values.modelo_status)
        formData.append('busto', values.modelo_busto)
        formData.append('cabelos', values.modelo_cabelos)
        formData.append('cintura', values.modelo_cintura)
        formData.append('manequim', values.modelo_manequim)
        formData.append('olhos', values.modelo_olhos)
        formData.append('pele', values.modelo_pele)
        formData.append('peso', values.modelo_peso)
        formData.append('altura', values.modelo_altura)
        formData.append('quadril', values.modelo_quadril)
        formData.append('sapatos', values.modelo_sapatos)
        formData.append('files', values.modelo_imagem[0].originFileObj)

        if (values.modelo_galeria) {
            values.modelo_galeria.map(function(foto, index) {
                formData.append('galeria['+index+']', foto.originFileObj)
                return 0
            })
        }

        await fetch('https://painel.v2models.com/php/menino/add.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) {
                    
                    formadd.setFieldsValue({
                        modelo_altura: '',
                        modelo_busto: '',
                        modelo_cabelos: '',
                        modelo_cintura: '',
                        modelo_id: '',
                        modelo_imagem: null,
                        modelo_manequim: '',
                        modelo_nome: '',
                        modelo_nome_artistico: '',
                        modelo_olhos: '',
                        modelo_pele: '',
                        modelo_peso: '',
                        modelo_quadril: '',
                        modelo_sapatos: '',
                        modelo_home: '',
                        modelo_banner: '',
                        modelo_status: '',
                    })
                    handleCancel()
                } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
            setLoadingFormNew(false)
        })

    };

    const onFinishImagens = async (values) => {

        setLoadingFormImg(true)
        const formData = new FormData()

        formData.append('id', values.modelo_id)

        values.modelo_galeria.map(function(foto, index) {
            formData.append('files['+index+']', foto.originFileObj)
            return 0
        })

        await fetch('https://painel.v2models.com/php/menino/galeria-add.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) {
                    form.setFieldsValue({
                        modelo_altura: '',
                        modelo_busto: '',
                        modelo_cabelos: '',
                        modelo_cintura: '',
                        modelo_id: '',
                        modelo_galeria: null,
                        modelo_imagem: null,
                        modelo_manequim: '',
                        modelo_nome: '',
                        modelo_nome_artistico: '',
                        modelo_olhos: '',
                        modelo_pele: '',
                        modelo_peso: '',
                        modelo_quadril: '',
                        modelo_sapatos: '',
                        modelo_home: '',
                        modelo_banner: '',
                        modelo_status: '',
                    })
                    handleCancel()      
                } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
            setLoadingFormImg(false)
        })


    };

    const onFinishEdit = async (values) => {
        setLoadingFormEdit(true);
        const formData = new FormData()

        formData.append('id', values.modelo_id)
        formData.append('nome', values.modelo_nome)
        formData.append('nomeart', values.modelo_nome_artistico)
        formData.append('home', values.modelo_home)
        formData.append('observacao', values.modelo_observacao)
        formData.append('posicao', values.modelo_posicao)
        formData.append('banner', values.modelo_banner)
        formData.append('disponivel', values.modelo_status)
        formData.append('busto', values.modelo_busto)
        formData.append('cabelos', values.modelo_cabelos)
        formData.append('cintura', values.modelo_cintura)
        formData.append('manequim', values.modelo_manequim)
        formData.append('olhos', values.modelo_olhos)
        formData.append('pele', values.modelo_pele)
        formData.append('peso', values.modelo_peso)
        formData.append('altura', values.modelo_altura)
        formData.append('quadril', values.modelo_quadril)
        formData.append('sapatos', values.modelo_sapatos)
        formData.append('files', (values.modelo_imagem_new) ? values.modelo_imagem_new[0].originFileObj : null)

        await fetch('https://painel.v2models.com/php/menino/edit.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) {
                    form.setFieldsValue({
                        modelo_altura: '',
                        modelo_busto: '',
                        modelo_cabelos: '',
                        modelo_cintura: '',
                        modelo_id: '',
                        modelo_imagem: null,
                        modelo_galeria: null,
                        modelo_manequim: '',
                        modelo_nome: '',
                        modelo_nome_artistico: '',
                        modelo_olhos: '',
                        modelo_pele: '',
                        modelo_peso: '',
                        modelo_quadril: '',
                        modelo_sapatos: '',
                        modelo_home: '',
                        modelo_banner: '',
                        modelo_status: '',
                    })
                    handleCancel()
                } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            setLoadingFormEdit(false)
            listData(search)
        })
    };

    // CONFIG TABLE
    const columns = [
        { title: 'Foto', dataIndex: 'foto', key: 'foto', render: (_, record) => {
            return (
                <Image
                    width={100}
                    src={record.imagem}
                />
            )
        }},
        { title: 'Nome', dataIndex: 'nome', key: 'nome', sorter: (a, b) => a.nome.length - b.nome.length},
        { title: 'Nome Art.', dataIndex: 'nome_artistico', key: 'nome_artistico', sorter: (a, b) => a.nome_artistico.length - b.nome_artistico.length },
        { title: 'Home', dataIndex: 'home', key: 'home', sorter: (a, b) => a.valor_home - b.valor_home },
        { title: 'Disponivel', dataIndex: 'disponivel', key: 'disponivel', sorter: (a, b) => a.valor_disponivel - b.valor_disponivel },
        { title: 'Ações', dataIndex: '', key: 'x', render: (_, record) => {
            return (
                <>
                    {record.valor_disponivel === '1' ? (
                        <Popconfirm title="Tem certeza que deseja retirar esse item do site?" onConfirm={() => homeData(record.id, 2)}>
                            <Button type="ghost" title="Retirar do site" icon={<MdWebAssetOff />} className="buttons_table" />
                        </Popconfirm>
                    ) : (
                        <Popconfirm title="Tem certeza que deseja enviar esse item para o site?" onConfirm={() => homeData(record.id, 3)}>
                            <Button title="Enviar para o site" type="ghost" icon={<MdWebAsset />} className="buttons_table" />
                        </Popconfirm>
                    )}
                    {record.exibe_na_home === '1' ? (
                        <Popconfirm title="Tem certeza que deseja retirar esse item da home?" onConfirm={() => homeData(record.id, 0)}>
                            <Button type="ghost" title="Retirar da home" icon={<IoClose />} className="buttons_table" />
                        </Popconfirm>
                    ) : (
                        <Popconfirm title="Tem certeza que deseja enviar esse item para a home?" onConfirm={() => homeData(record.id, 1)}>
                            <Button type="ghost" title="Enviar para a home" icon={<IoHome />} className="buttons_table" />
                        </Popconfirm>
                    )}
                    <Tooltip title="Editar">
                        <Button type="primary" icon={<IoPencil />} className="buttons_table" onClick={() => loadData(record)} />
                    </Tooltip>
                    <Popconfirm title="Tem certeza que deseja deletar esse item?" onConfirm={() => deleteData(record.id)}>
                        <Button type="danger" icon={<IoTrashBin />} className="buttons_table" />
                    </Popconfirm>
                </>
            )
        } }
    ];

    const homeData = async (id, type) => {
        
        const formData = new FormData()
        var url = '';

        formData.append('id', id)
        formData.append('table', 'v2_kids_meninos');

        if (type === 1) {
            url = 'https://painel.v2models.com/php/home/enviar.php'
        } else if (type === 0) {
            url = 'https://painel.v2models.com/php/home/retirar.php'
        } else if (type === 3) {
            url = 'https://painel.v2models.com/php/home/disenviar.php'
        } else if (type === 2) {
            url = 'https://painel.v2models.com/php/home/disretirar.php'
        }

        await fetch(url, { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) { } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })

    }

    const listData = async (value = search, pagCurrentThis = pagCurrent, pagSizeThis = pagSize) => {
        setLoading(true)
        const formData = new FormData()
    
        formData.append('search', value)
        formData.append('current', pagCurrentThis)
        formData.append('pageSize', pagSizeThis)
    
        await fetch('https://painel.v2models.com/php/menino/list.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            setList(res.data);
            setPagTotal(res.summary.QTDE)
            setLoading(false)
        })
    
    }

    const _onChange = (values) => {
        setPagCurrent(values)
        listData(search, values, pagSize)
    }

    const _onPaginationSize = async (size) => {
        setPagSize(size)
        listData(search, pagCurrent, size)
    }

    return (
        <Layout>
            <HeaderComponent title="Modelo" subtitle="Criação, alteração e exclusão de modelos menino" />

            {/* CARD EDIT */}
            <Modal title="Editar Modelo" width={1000} visible={visible} footer={[]} onCancel={handleCancel}>
                <Card size="small" title="Editar modelo" className="cliente_card_list">
                <Form layout="vertical" name="form_data" onFinish={onFinishEdit} form={form}>
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Form.Item name="modelo_imagem_old" label="Imagem">
                                <Image width={200} src={data?.imagem} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="modelo_imagem_new" label="Nova Imagem" valuePropName="fileList" getValueFromEvent={normFile} >
                                <Upload maxCount={1} accept="image/png, image/jpeg" name="logo" listType="picture">
                                    <Button >Clique para selecionar a imagem</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="modelo_nome" label="Nome" rules={[{ required: true, message: 'Por favor, insira um valor' }]} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="modelo_nome_artistico" label="Nome Artístico" rules={[{ required: true, message: 'Por favor, insira um valor' }]} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="modelo_pele" label="Pele" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select placeholder="Selecione uma opção" >
                                    <Select.Option value="Branca">Branca</Select.Option>
                                    <Select.Option value="Morena">Morena</Select.Option>
                                    <Select.Option value="Negra">Negra</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="modelo_olhos" label="Olhos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select placeholder="Selecione uma opção" >
                                    <Select.Option value="Azuis">Azuis</Select.Option>
                                    <Select.Option value="Castanhos">Castanhos</Select.Option>
                                    <Select.Option value="Castanhos Claros">Castanhos Claros</Select.Option>
                                    <Select.Option value="Mel">Mel</Select.Option>
                                    <Select.Option value="Pretos">Pretos</Select.Option>
                                    <Select.Option value="Verde">Verde</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item name="modelo_cabelos" label="Cabelos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select placeholder="Selecione uma opção" >
                                    <Select.Option value="Castanhos">Castanhos</Select.Option>
                                    <Select.Option value="Castanhos Claros">Castanhos Claros</Select.Option>
                                    <Select.Option value="Loiros">Loiros</Select.Option>
                                    <Select.Option value="Pretos">Pretos</Select.Option>
                                    <Select.Option value="Ruivos">Ruivos</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="modelo_altura" label="Altura(cm)" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="modelo_peso" label="Peso(kg)" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="modelo_manequim" label="Manequim" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="modelo_busto" label="Busto" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="modelo_cintura" label="Cintura" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="modelo_quadril" label="Quadril" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="modelo_sapatos" label="Sapatos" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="modelo_observacao" label="Observações" >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="modelo_id" hidden initialValue={0} >
                        <Input />
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" loading={loadingFormEdit}>
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
                </Card>
                <Card size="small" title="Galeria de fotos" className="cliente_card_list">
                <Row gutter={24}>
                    {data?.list_images.map((foto, index) => (
                        <Col key={index} span={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Image
                                width={150}
                                src={foto.imagem}
                            />
                            <Popconfirm title="Tem certeza que deseja deletar esse item?" onConfirm={() => deletePhotoData(foto.Id)}>
                                <Button style={{marginTop: 8}} size="small" type="danger">Deletar</Button>
                            </Popconfirm>
                        </Col>
                    ))}
                    </Row>
                    <Divider />
                    <Form layout="vertical" name="form_data" style={{marginTop: 10}} onFinish={onFinishImagens} form={formImagem}>
                        <Form.Item name="modelo_galeria" label="Selecionar fotos" valuePropName="fileList" getValueFromEvent={normFile} >
                            <Upload maxCount={20} multiple accept="image/png, image/jpeg" name="logo" listType="picture">
                                <Button >Clique para selecionar a imagem</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item name="modelo_id" hidden initialValue={0} >
                            <Input />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={loadingFormImg}>
                                Salvar Arquivos
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>

            {/* CARD Novo */}
            <Modal title="Novo Modelo" width={1000} visible={visibleAdd} footer={[]} onCancel={handleCancel}>
                <Form layout="vertical" name="form_data" onFinish={onFinish} form={formadd}>
                <Card size="small" title="Novo modelo" className="cliente_card_list">
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="modelo_imagem"
                                label="Nova Imagem"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                            >
                                <Upload maxCount={1} accept="image/png, image/jpeg" name="logo" listType="picture">
                                    <Button >Clique para selecionar a imagem</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="modelo_nome"
                                label="Nome"
                                rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <Form.Item
                                name="modelo_nome_artistico"
                                label="Nome Artístico"
                                rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="modelo_pele" label="Pele" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="Branca">Branca</Select.Option>
                                    <Select.Option value="Morena">Morena</Select.Option>
                                    <Select.Option value="Negra">Negra</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={5}>
                            <Form.Item name="modelo_olhos" label="Olhos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="Azuis">Azuis</Select.Option>
                                    <Select.Option value="Castanhos">Castanhos</Select.Option>
                                    <Select.Option value="Castanhos Claros">Castanhos Claros</Select.Option>
                                    <Select.Option value="Mel">Mel</Select.Option>
                                    <Select.Option value="Pretos">Pretos</Select.Option>
                                    <Select.Option value="Verde">Verde</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={6}>
                            <Form.Item name="modelo_cabelos" label="Cabelos" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="Castanhos">Castanhos</Select.Option>
                                    <Select.Option value="Castanhos Claros">Castanhos Claros</Select.Option>
                                    <Select.Option value="Loiros">Loiros</Select.Option>
                                    <Select.Option value="Pretos">Pretos</Select.Option>
                                    <Select.Option value="Ruivos">Ruivos</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={3}>
                            <Form.Item
                                name="modelo_altura"
                                label="Altura(cm)"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={3}>
                            <Form.Item
                                name="modelo_peso"
                                label="Peso(kg)"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="modelo_manequim"
                                label="Manequim"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item
                                name="modelo_busto"
                                label="Busto"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item
                                name="modelo_cintura"
                                label="Cintura"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item
                                name="modelo_quadril"
                                label="Quadril"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            <Form.Item
                                name="modelo_sapatos"
                                label="Sapatos"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="modelo_observacao"
                                label="Observações"
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="modelo_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                </Card>
                <Card size="small" title="Galeria de fotos" className="cliente_card_list">
                    <Form.Item
                        name="modelo_galeria"
                        label="Selecionar fotos"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload maxCount={20} multiple accept="image/png, image/jpeg" name="logo" listType="picture">
                            <Button >Clique para selecionar a imagem</Button>
                        </Upload>
                    </Form.Item>
                </Card>
                <Button type="primary" htmlType="submit" loading={loadingFormNew}>
                    Enviar
                </Button>
                </Form>
            </Modal>

            {/* CARD LIST */}
            <Card size="small" title="Lista de Modelos meninos" className="cliente_card_list">
                <Button type="primary" size="small" onClick={() => setVisibleAdd(true)}>Novo modelo</Button>
                <Input size="small" value={search} onChange={(e) => loadSearch(e.target.value)} placeholder="Pesquisar..." className="cliente_card_list_search"/>
                <Table
                    columns={columns}
                    dataSource={list}
                    loading={loading}
                    pagination={false}
                />
                <Pagination 
                    style={{marginTop: 10, float: 'right'}}
                    showSizeChanger
                    onShowSizeChange={(current, size) => _onPaginationSize(size)}
                    defaultCurrent={pagCurrent}
                    total={pagTotal}
                    onChange={_onChange}
                />
            </Card>
        </Layout>
    )

}


export default withCookies(Screen);