import React, { useState } from "react";
import { Typography, Layout, Row, Button, Image } from 'antd';
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { withCookies, useCookies } from "react-cookie";

// IMAGE
import Logo from '../../assets/images/logo-alternativa.png';

// ICONS
import { IoMenu, IoPower, IoPerson, IoPersonAdd, IoAccessibility, IoBriefcase, IoSpeedometer } from 'react-icons/io5';

// CSS
import './styles.css';

const Screen = () => {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['login']);
    const { Header, Content, Footer, Sider } = Layout
    const { Text } = Typography

    // STATES
    const [menu, setMenu] = useState(false)

    const ItemsMenu = [
        {label: 'Dashboard', url: '', icon: <IoSpeedometer color="#b8c7ce" size={14} style={{marginRight: 10}} />},
        {label: 'Clientes', url: 'clientes', icon: <IoPersonAdd color="#b8c7ce" size={14} style={{marginRight: 10}} />},
        // {label: 'Home', url: 'home', icon: <IoPersonAdd color="#b8c7ce" size={14} style={{marginRight: 10}} />},
        {label: 'Masculino', url: 'masculino', icon: <IoPerson color="#b8c7ce" size={14} style={{marginRight: 10}} />},
        {label: 'Feminino', url: 'feminino', icon: <IoPerson color="#b8c7ce" size={14} style={{marginRight: 10}} />},
        {label: 'Meninos', url: 'meninos', icon: <IoAccessibility color="#b8c7ce" size={14} style={{marginRight: 10}} />},
        {label: 'Meninas', url: 'meninas', icon: <IoAccessibility color="#b8c7ce" size={14} style={{marginRight: 10}} />},
        {label: 'Trabalhos', url: 'trabalhos', icon: <IoBriefcase color="#b8c7ce" size={14} style={{marginRight: 10}} />},
        // {label: 'Trabalhos e Modelos', url: 'trabalhos-modelos', icon: <IoBriefcase color="#b8c7ce" size={14} style={{marginRight: 10}} />},
    ];

    const onLogout = () => {
        removeCookie('login')
        navigate('/')
    }

    return (
        <Layout className="home_box">
            <Header className="home_box__header">
                <Row className="home_box__header_row">
                    <Button type="text" shape="circle" onClick={() => setMenu(!menu)}><IoMenu color="white" size={22} /></Button>
                    <Image
                        preview={false}
                        src={Logo}
                        className="home_box__header_row_image"
                    />
                    <Button type="text" shape="circle" onClick={onLogout}><IoPower color="white" size={22} /></Button>
                </Row>
            </Header>
            <Layout className="home_box__box">
                <Sider className={menu ? "home_box__box_sider active" : "home_box__box_sider"}>
                    <Row className="home_box__box_sider_items">
                        <div to='#' className="home_box__box_sider_items_menu_first">Menu de navegação</div>
                        {ItemsMenu.map((item, index) => (
                            <Link key={index} to={item.url} className="home_box__box_sider_items_menu">{item.icon} {item.label}</Link>
                        ))}
                    </Row>
                </Sider>
                <Layout className="home_box__box__box">
                    <Content className="home_box__box__box_content">
                        <Outlet />
                    </Content>
                    <Footer className="home_box__box__box_footer">
                        <Text className="home_box__box__box_footer_text">© V2 - Todos os direitos Reservados</Text>
                    </Footer>
                </Layout>
            </Layout>
        </Layout>
    )

}


export default withCookies(Screen);