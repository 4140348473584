import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Layout, Card, Button, Form, Upload, Input, Select, Table, Tooltip, Popconfirm, Modal, Image, Row, Col } from 'antd';
import { withCookies } from "react-cookie";

// ICONS
import { IoPencil, IoTrashBin, IoClose, IoHome } from 'react-icons/io5';

// CSS
import './styles.css';
import HeaderComponent from "../../components/HeaderComponent";

const Screen = () => {

    // CONFIG STATES
    const [visible, setVisible] = useState(false)
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [list, setList] = useState([])
    const [listCliente, setListCliente] = useState([])
    const [data, setData] = useState()

    const [formadd] = Form.useForm();
    const [form] = Form.useForm();

    const showModal = () => {
        setVisible(true);
    };
    
    const handleCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (!load) {
            listData(search);
            listDataCliente();
            setLoad(true)
        }
    }, [load, search]);

    const listData = async (value) => {
        setLoading(true)
        const formData = new FormData()
    
        formData.append('search', value)
    
        await fetch('https://painel.v2models.com/php/trabalhos/list.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            setList(res);
            setLoading(false)
        })
    
    }

    const listDataCliente = async () => {
        const formData = new FormData()
    
        formData.append('search', '')
    
        await fetch('https://painel.v2models.com/php/clientes/list.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            setListCliente(res);
        })
    
    }

    const loadData = (item) => {
        showModal()
        form.setFieldsValue({
            trabalho_imagem_old: item.imagem,
            trabalho_id: item.id,
            trabalho_titulo: item.titulo,
            trabalho_descricao: item.descricao,
            trabalho_cliente: item.cliente,
            trabalho_status: item.valor_status,
            trabalho_home: item.exibe_na_home,
            trabalho_posicao: item.posicao,
        })
        setData(item)
    }

    const loadSearch = (value) => {
        setSearch(value)
        listData(value)
    }

    const deleteData = async (id) => {
        const formData = new FormData()
    
        formData.append('id', id)
    
        await fetch('https://painel.v2models.com/php/trabalhos/del.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) { } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })
    }

    // CONFIG FORM
    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    const onFinish = async (values) => {
        const formData = new FormData()

        formData.append('id', values.trabalho_id)
        formData.append('titulo', values.trabalho_titulo)
        formData.append('descricao', values.trabalho_descricao)
        formData.append('cliente', values.trabalho_cliente)
        formData.append('status', values.trabalho_status)
        formData.append('home', values.trabalho_home)
        formData.append('posicao', values.trabalho_posicao)
        formData.append('files', values.trabalho_imagem[0].originFileObj)
        
        await fetch('https://painel.v2models.com/php/trabalhos/add.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) {
                    formadd.setFieldsValue({
                        trabalho_imagem: null,
                        trabalho_id: '',
                        trabalho_titulo: '',
                        trabalho_descricao: '',
                        trabalho_cliente: '',
                        trabalho_status: '',
                        trabalho_home: '',
                        trabalho_posicao: '',
                    })
                } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })
    };

    const onFinishEdit = async (values) => {
        const formData = new FormData()
    
        formData.append('id', values.trabalho_id)
        formData.append('titulo', values.trabalho_titulo)
        formData.append('descricao', values.trabalho_descricao)
        formData.append('cliente', values.trabalho_cliente)
        formData.append('status', values.trabalho_status)
        formData.append('home', values.trabalho_home)
        formData.append('posicao', values.trabalho_posicao)
        formData.append('files', (values.trabalho_imagem_new) ? values.trabalho_imagem_new[0].originFileObj : null)
        
        await fetch('https://painel.v2models.com/php/trabalhos/edit.php', { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) {
                    form.setFieldsValue({
                        trabalho_imagem_new: null,
                        trabalho_id: '',
                        trabalho_titulo: '',
                        trabalho_descricao: '',
                        trabalho_cliente: '',
                        trabalho_status: '',
                        trabalho_home: '',
                        trabalho_posicao: '',
                    })
                    handleCancel()
                } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })
    };

    // CONFIG TABLE
    const columns = [
        { title: 'Imagem', dataIndex: 'imagem', key: 'imagem', render: (_, record) => {
            return (
                <Image
                    width={100}
                    src={record.foto}
                />
            )
        }},
        { title: 'Titulo', dataIndex: 'titulo', key: 'titulo', sorter: (a, b) => a.titulo - b.titulo },
        { title: 'Status', dataIndex: 'status', key: 'status', sorter: (a, b) => a.status - b.status },
        { title: 'Ações', dataIndex: '', key: 'x', render: (_, record) => {
            return (
                <>
                    {record.exibe_na_home === '1' ? (
                        <Popconfirm title="Tem certeza que deseja retirar esse item da home?" onConfirm={() => homeData(record.id, 0)}>
                            <Button type="ghost" icon={<IoClose />} className="buttons_table" />
                        </Popconfirm>
                    ) : (
                        <Popconfirm title="Tem certeza que deseja enviar esse item para a home?" onConfirm={() => homeData(record.id, 1)}>
                            <Button type="ghost" icon={<IoHome />} className="buttons_table" />
                        </Popconfirm>
                    )}
                    <Tooltip title="Editar">
                        <Button type="primary" icon={<IoPencil />} className="buttons_table" onClick={() => loadData(record)} />
                    </Tooltip>
                    <Popconfirm title="Tem certeza que deseja deletar esse item?" onConfirm={() => deleteData(record.id)}>
                        <Button type="danger" icon={<IoTrashBin />} className="buttons_table" />
                    </Popconfirm>
                </>
            )
        } }
    ];

    const homeData = async (id, type) => {
        
        const formData = new FormData()
        var url = '';

        formData.append('id', id)
        formData.append('table', 'v2_trabalhos');

        if (type === 1) {
            url = 'https://painel.v2models.com/php/home/trabalhos.php'
        } else {
            url = 'https://painel.v2models.com/php/home/retirar.php'
        }

        await fetch(url, { method: 'POST', body: formData }).then(rs => rs.json()).then(res => {
            Swal.fire({...res}).then((e) => { }).then(e => {
                if(res.return) { } 
            })
        }).catch(e => {
            Swal.fire({
                title: 'Erro',
                text: 'Um erro inesperado ocorreu.',
                icon: 'error'
            })
        }).finally(e => {
            listData(search)
        })

    }

    return (
        <Layout>
            <HeaderComponent title="Trabalhos" subtitle="Criação, alteração e exclusão de trabalhos" />

            {/* CARD ADD */}
            <Card size="small" title="Adicionando Trabalhos" className="cliente_card_add">
                <Form layout="vertical" name="form_data" onFinish={onFinish} form={formadd}>
                    <Form.Item
                        name="trabalho_imagem"
                        label="Imagem"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: 'Por favor, selecione uma imagem' }]}
                    >
                        <Upload maxCount={1} accept="image/png, image/jpeg" name="logo" listType="picture">
                            <Button >Clique para selecionar a imagem</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="trabalho_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="trabalho_titulo"
                        label="Nome"
                        rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="trabalho_descricao"
                        label="Descrição"
                        rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item name="trabalho_cliente" label="Cliente" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    {listCliente.map((item, index) => (
                                        <Select.Option value={item.id}>{item.nome}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="trabalho_status" label="Status" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="1">Ativo</Select.Option>
                                    <Select.Option value="0">Inativo</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="trabalho_home" label="Exibir na home" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="1">Sim</Select.Option>
                                    <Select.Option value="0">Não</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="trabalho_posicao" label="Posição">
                        <Input type='number' />
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            {/* CARD EDIT */}
            <Modal title="Editar Trabalho" width={1000} visible={visible} footer={[]} onCancel={handleCancel}>
                <Card size="small" title="Editar trabalho" className="cliente_card_list">
                <Form layout="vertical" name="form_data" onFinish={onFinishEdit} form={form}>
                    <Form.Item
                        name="trabalho_imagem_old"
                        label="Imagem"
                    >
                        <Image
                            width={200}
                            src={data?.imagem}
                        />
                    </Form.Item>
                    <Form.Item
                        name="trabalho_imagem_new"
                        label="Imagem"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload maxCount={1} accept="image/png, image/jpeg" name="logo" listType="picture">
                            <Button >Clique para selecionar a imagem</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="trabalho_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="trabalho_titulo"
                        label="Titulo"
                        rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="trabalho_descricao"
                        label="Descrição"
                        rules={[{ required: true, message: 'Por favor, insira um valor' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item name="trabalho_cliente" label="Cliente" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    {listCliente.map((item, index) => (
                                        <Select.Option value={item.id}>{item.nome}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="trabalho_status" label="Status" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="1">Ativo</Select.Option>
                                    <Select.Option value="0">Inativo</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="trabalho_home" label="Exibir na home" rules={[{ required: true, message: 'Por favor, selecione um valor' }]}>
                                <Select
                                    placeholder="Selecione uma opção"
                                >
                                    <Select.Option value="1">Sim</Select.Option>
                                    <Select.Option value="0">Não</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="trabalho_posicao" label="Posição">
                        <Input type='number' />
                    </Form.Item>
                    <Form.Item
                        name="modelo_id"
                        hidden
                        initialValue={0}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
                </Card>
            </Modal>

            {/* CARD LIST */}
            <Card size="small" title="Lista de Trabalhos" className="cliente_card_list">
                
                <Input value={search} onChange={(e) => loadSearch(e.target.value)} placeholder="Pesquisar..." className="cliente_card_list_search"/>
                <Table
                    columns={columns}
                    dataSource={list}
                    loading={loading}
                />
            </Card>
        </Layout>
    )

}


export default withCookies(Screen);