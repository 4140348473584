import React, { useEffect, useState } from "react";
import { Typography, Row, Breadcrumb  } from 'antd';
import { withCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";

// CSS
import './styles.css';

const Screen = ({title, subtitle}) => {

    const { Text } = Typography
    const [verify, setVerify] = useState(false)
    const [cookies, setCookie] = useCookies(['login']);

    const navigate = useNavigate()

    useEffect(() => {
        if (!verify) {
            if (cookies.login !== 'TRUE') {
                navigate('/')
            }
        }
    }, [verify]);

    return (
        <Row className="component_header_row">
            <Row className="component_header_row__row">
                <Text className="component_header_row__row_title">{title}</Text>
                <Text className="component_header_row__row_subtitle">{subtitle}</Text>
            </Row>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/dashboard">
                        <span>Home</span>
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>
        </Row>
    )

}


export default withCookies(Screen);