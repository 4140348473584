import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import HomeScreen from "../pages/HomeScreen";
import DashboardScreen from "../pages/DashboardScreen";
import ClienteScreen from "../pages/ClienteScreen";
import SlideScreen from "../pages/SlideScreen";
import MasculinoScreen from "../pages/MasculinoScreen";
import FemininoScreen from "../pages/FemininoScreen";
import MeninoScreen from "../pages/MeninoScreen";
import MeninaScreen from "../pages/MeninaScreen";
import TrabalhoScreen from "../pages/TrabalhoScreen";
import TrabalhoModeloScreen from "../pages/TrabalhoModeloScreen";

// LOGIN
import LoginScreen from "../pages/LoginScreen";

// DASHBOARD

const AppStack = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Outlet/>}>
                    <Route path="" element={<LoginScreen />} />
                    <Route path="dashboard" element={<HomeScreen />} >
                        <Route path="" element={<DashboardScreen />} />
                        <Route path="clientes" element={<ClienteScreen />} />
                        <Route path="home" element={<SlideScreen />} />
                        <Route path="masculino" element={<MasculinoScreen />} />
                        <Route path="feminino" element={<FemininoScreen />} />
                        <Route path="meninos" element={<MeninoScreen />} />
                        <Route path="meninas" element={<MeninaScreen />} />
                        <Route path="trabalhos" element={<TrabalhoScreen />} />
                        <Route path="trabalhos-modelos" element={<TrabalhoModeloScreen />} />
                        <Route path="*" element={<>Página não encontrada</>} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )

}

export default AppStack;